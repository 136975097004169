import React from 'react';
import { CURRENT_CURRENCY_SYMBOL } from '@/config/paymentLinks';
import { hexToRgba } from '../../../ModernTheme/utils/common';
import {
  getProductActualPriceStyles,
  getProductDescriptionStyles,
  getProductNameStyles,
  getProductPriceStyles,
} from '../utils';
import classNames from 'classnames';
import { RatingComponent } from './RatingComponent';
import { AddToCartButton } from './AddToCartButton';
import { OverlayItemsAligner } from './OverlayItemsAligner';
import { DiscountTag } from './DiscountTag';
import { getStoreType } from 'src/utils/getStoreType';
import { STORE_CUSTOMIZATION } from 'src/utils/constants';
import { removeHTMLTagsFromString } from 'src/utils/removeHTMLTagsFromString';
import { VariantsRenderer } from './VariantsRenderer';
import { useSSRSelector } from 'src/redux';
import { useFeatureLocks } from 'src/hooks/useFeatureLocks';
import { Tags } from './Tags';
import { formatPriceToINR } from 'src/utils/formatPricing';

export function BelowElementPicker({
  isMobile,
  productData,
  elementData,
  renderrerList,
  adjacentElements,
  snackbarMethods,
  overrideStyles,
}) {
  const reviewSettings = useSSRSelector((state) => state.catalogReducer.reviewSettings);
  const { crrFeatureStatus } = useFeatureLocks();
  const config = elementData.config;
  const variantComponentConfig = renderrerList.find(
    (item) => item.type === 'variant'
  )?.config;

  const hasChoosenVariant = !!productData.item_option_variants?.some(
    (item) => item[variantComponentConfig?.variantType]
  );

  const showChoosenVariants = variantComponentConfig?.showVariant && hasChoosenVariant;

  let ratingConfigs;
  if (elementData.type === 'rating' || adjacentElements?.next === 'rating') {
    const ratingData = productData?.store_item_review_rating;
    const ratingComponentConfig = renderrerList.find(
      (item) => item.type === 'rating'
    )?.config;

    // const checkShowForAvgRating =
    //   ratingComponentConfig?.minAvgRating &&
    //   ratingData?.review_rating?.[0]?.avg_rating >= 3.5;
    // const checkForAtleastMinNum =
    //   ratingComponentConfig?.minRating &&
    //   (ratingData?.review_rating?.[0]?.ratings_count || ratingData?.ratings_count) >= 3;

    const hasRating =
      ratingComponentConfig?.showRatings &&
      !crrFeatureStatus?.isLocked &&
      reviewSettings?.status &&
      ratingData?.review_rating &&
      ratingData?.review_rating[0]?.avg_rating;
    ratingConfigs = { hasRating };
  }
  // Name & description are clubbed together
  switch (elementData.type) {
    case 'name': {
      const productNameStyles = getProductNameStyles(config);
      const descriptionComponent = renderrerList.find(
        (item) => item.type === 'description'
      );
      const marginSpacerClass = (() => {
        if (adjacentElements?.isLastItem) return null;
        switch (adjacentElements?.next) {
          case 'variant':
            return showChoosenVariants ? 'tw-mb-[12px]' : null;
          case 'rating':
            return ratingConfigs?.hasRating ? 'tw-mb-[12px]' : null;
          case 'price':
            return 'tw-mb-[10px]';
          default:
            return null;
        }
      })();
      const productDescriptionStyles = getProductDescriptionStyles(
        descriptionComponent?.config,
        isMobile
      );
      return (
        <>
          <div className="cardIntroWrapper">
            <p
              className={classNames(
                'tw-m-0 tw-text-[16px]',
                config?.productNameNumOfLine !== 'one' && 'tw-leading-[1.4]',
                productNameStyles.className
              )}
              style={{
                color: overrideStyles?.textColor || config.productNameColor,
                ...productNameStyles.style,
              }}
            >
              {removeHTMLTagsFromString(productData?.name)}
            </p>
            {descriptionComponent?.config?.showDescription &&
              !!productData?.description?.trim() && (
                <p
                  className={classNames(
                    'tw-m-0 tw-text-[12px] tw-font-normal', // tw-break-all
                    config?.[
                      !isMobile
                        ? 'productdescriptionDesktopNumOfLines'
                        : 'productdescriptionMobileNumOfLines'
                    ] !== 'one' && 'tw-leading-[1.4]',
                    isMobile ? 'tw-mt-[6px]' : 'tw-mt-[8px]',
                    productDescriptionStyles.className
                  )}
                  style={{
                    color:
                      hexToRgba(overrideStyles?.textColor, 0.6) ||
                      descriptionComponent?.config.productDescriptionColor,
                    ...productDescriptionStyles.style,
                    overflowWrap: 'anywhere',
                    hyphens: 'auto',
                  }}
                >
                  {removeHTMLTagsFromString(productData?.description)}
                </p>
              )}
          </div>
          {!!marginSpacerClass && <div className={marginSpacerClass} />}
        </>
      );
    }

    case 'description': {
      const marginSpacerClass = (() => {
        if (
          (adjacentElements?.above !== 'name' && adjacentElements?.above !== 'price') ||
          adjacentElements?.isLastItem
        )
          return null;
        switch (adjacentElements?.next) {
          case 'price':
            return 'tw-mb-[10px]';
          case 'variant':
            return showChoosenVariants ? 'tw-mb-[12px]' : null;
          case 'rating':
            return ratingConfigs?.hasRating ? 'tw-mb-[12px]' : null;
          default:
            return null;
        }
      })();
      return !!marginSpacerClass && <div className={marginSpacerClass} />;
    }

    case 'price': {
      const discountComponent = renderrerList.find((item) => item.type === 'discount');
      const productPriceStyles = getProductPriceStyles(config);
      const productActualPriceStyles = getProductActualPriceStyles(
        productPriceStyles.style
      );
      const isB2bStore = getStoreType() === STORE_CUSTOMIZATION.B2B;
      const marginSpacerClass = (() => {
        if (adjacentElements?.isLastItem) return null;
        switch (adjacentElements?.next) {
          case 'button':
            return null;
          case 'variant':
            return showChoosenVariants ? 'tw-mb-[12px]' : null;
          case 'rating':
            return ratingConfigs?.hasRating ? 'tw-mb-[12px]' : null;
          case 'name':
            return 'tw-mb-[10px]';
          default:
            return null;
        }
      })();

      return (
        <>
          <div
            className={classNames(
              'pricingSection tw-flex tw-flex-wrap tw-gap-x-[8px] tw-gap-y-[6px]',
              discountComponent?.config?.discountStyle === 'text'
                ? 'tw-items-end'
                : 'tw-items-center'
            )}
          >
            {!isB2bStore ? (
              <>
                <div className="pricingSection tw-flex tw-flex-wrap tw-items-end tw-gap-x-[4px] tw-gap-y-[6px]">
                  <p
                    className="tw-relative tw-bottom-[-0.5px] tw-m-0 tw-leading-[0.88] tw-text-newBlack"
                    style={{
                      color: overrideStyles?.textColor || config.productPriceColor,
                      ...productPriceStyles.style,
                    }}
                  >
                    <span>{CURRENT_CURRENCY_SYMBOL}</span>
                    <span>{formatPriceToINR(productData?.discounted_price)}</span>
                  </p>
                  {productData?.discounted_price !== productData?.price ? (
                    <p
                      style={{
                        color: hexToRgba(
                          overrideStyles?.textColor || config.productPriceColor,
                          0.6
                        ),
                        ...productActualPriceStyles.style,
                      }}
                      className="tw-m-0 tw-text-[13px] tw-leading-[0.88] tw-text-newBlack/[0.6] tw-line-through"
                    >
                      {CURRENT_CURRENCY_SYMBOL}
                      {formatPriceToINR(productData?.price)}
                    </p>
                  ) : null}
                </div>
                <DiscountTag
                  productData={productData}
                  config={discountComponent?.config}
                  isMobile={isMobile}
                />
              </>
            ) : (
              <p
                className="tw-m-0 tw-text-[14px] tw-leading-[1] tw-text-newBlack"
                style={{
                  color: overrideStyles?.textColor || config.productPriceColor,
                  ...productPriceStyles.style,
                }}
              >
                {productData?.b2b_pricing_info?.price_range || 'Price on Request'}
              </p>
            )}
          </div>
          {!!marginSpacerClass && <div className={marginSpacerClass} />}
        </>
      );
    }
    case 'button':
      return config?.showAddToCart ? (
        <>
          <div className="tw-mt-auto" />
          <div className={isMobile ? 'tw-mt-[16px]' : 'tw-mt-[20px]'} />
          <AddToCartButton
            isMobile={isMobile}
            productData={productData}
            config={config}
            snackbarMethods={snackbarMethods}
            overrideStyles={overrideStyles}
          />
        </>
      ) : null;
    case 'variant': {
      const marginSpacerClass = (() => {
        if (adjacentElements?.isLastItem) return null;
        switch (adjacentElements?.next) {
          case 'price':
          case 'name':
            return 'tw-mb-[12px]';
          case 'rating':
            return ratingConfigs?.hasRating ? 'tw-mb-[12px]' : null;
          default:
            return null;
        }
      })();
      return showChoosenVariants ? (
        <>
          <VariantsRenderer
            productData={productData}
            config={config}
            overrideStyles={overrideStyles}
            isMobile={isMobile}
          />
          {!!marginSpacerClass && <div className={marginSpacerClass} />}
        </>
      ) : null;
    }
    case 'rating':
      return (
        <RatingComponent
          productData={productData}
          type={2}
          overrideStyles={overrideStyles}
          config={config}
          insertMarginBottom={
            !adjacentElements?.isLastItem
              ? (adjacentElements?.next === 'variant' && showChoosenVariants) ||
                adjacentElements?.next === 'price' ||
                adjacentElements?.next === 'name' ||
                (adjacentElements?.next === 'rating' && ratingConfigs?.hasRating)
              : false
          }
        />
      );
    default:
      return null;
  }
}

export function OverlayElementPicker({
  isMobile,
  productData,
  elementData,
  snackbarMethods,
}) {
  const config = elementData.config;
  switch (elementData.type) {
    case 'rating': {
      return (
        <OverlayItemsAligner coordinates={config.coordinates} isMobile={isMobile}>
          <RatingComponent productData={productData} type={1} config={config} />
        </OverlayItemsAligner>
      );
    }
    case 'discount': {
      return (
        <OverlayItemsAligner
          coordinates={config.coordinates}
          config={config}
          isMobile={isMobile}
        >
          <DiscountTag productData={productData} config={config} isMobile={isMobile} />
        </OverlayItemsAligner>
      );
    }
    case 'button':
      return config?.showAddToCart ? (
        <OverlayItemsAligner
          coordinates={config.coordinates}
          isMobile={isMobile}
          customStyles={{
            width:
              config?.buttonType === 'icon'
                ? 'max-content'
                : `calc(100% - ${isMobile ? 16 : 20}px)`,
          }}
        >
          <AddToCartButton
            isMobile={isMobile}
            productData={productData}
            config={config}
            isOverlay
            snackbarMethods={snackbarMethods}
          />
        </OverlayItemsAligner>
      ) : null;
    case 'tags':
      return (
        <OverlayItemsAligner
          coordinates={config.coordinates}
          isMobile={isMobile}
          customClass="tw-flex tw-flex-wrap"
          customStyles={{
            width: 'max-content',
          }}
        >
          <Tags isMobile={isMobile} productData={productData} config={config} />
        </OverlayItemsAligner>
      );
    default:
      return null;
  }
}
