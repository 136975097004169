import classNames from 'classnames';
import React, { useMemo } from 'react';
import { getVariantSingularTextStyles, getVariantTextStyles } from '../utils';
import { hexToRgba } from 'src/components/DesignSystem/ModernTheme/utils/common';

export function VariantsRenderer({ productData, config, isMobile, overrideStyles }) {
  const hasVariant = productData.item_option_variants?.length;
  const overrideTextColor = overrideStyles?.textColor;

  const variantsList = useMemo(
    () =>
      hasVariant
        ? productData.item_option_variants?.find((item) => item[config?.variantType])?.[
            config?.variantType
          ] || []
        : [],
    [productData.item_option_variants, config?.variantType]
  );

  if (!hasVariant || !config?.showVariant) {
    return null;
  }

  if (!variantsList?.length) {
    return null;
  }

  const isTextOnly = config?.variantShape === 'text';
  const isCircle = config?.variantShape?.includes('circle');
  const isVariantTypeColor = ['colour', 'color'].includes(
    config?.variantType?.toLowerCase()
  );
  const variantTextStyles = getVariantTextStyles(config);

  const maxVariantsNum = isMobile ? 4 : 6;
  const choosenVariantList = variantsList.slice(0, maxVariantsNum);

  if (!choosenVariantList?.length) {
    return null;
  }

  const extraVariantSingularStyle = getVariantSingularTextStyles(config, {
    isColorVariant: false,
    isTextOnly,
    isOneCharacterName: false,
  });
  return (
    <div
      className={classNames(
        'tw-flex tw-items-center',
        !isTextOnly || config?.separateVariantWithComa
          ? 'tw-gap-x-[6px] tw-gap-y-[6px]'
          : 'tw-gap-x-[3px] tw-gap-y-[3px]'
      )}
    >
      {choosenVariantList.map((variant, index, arr) => {
        const isColorVariant = variant?.colour_code;
        const variantName = variant?.variant_name;
        const isOneCharacterName = variant?.variant_name?.length === 1;
        const variantSingularStyle = getVariantSingularTextStyles(config, {
          isColorVariant: isVariantTypeColor,
          isTextOnly,
          isOneCharacterName,
        });

        return (
          <div
            key={variant?.variant_name_id}
            className={classNames(
              'tw-flex tw-items-center tw-justify-center tw-font-medium',
              isColorVariant &&
                !isTextOnly &&
                (!isMobile ? 'tw-h-[8px] tw-w-[8px]' : 'tw-h-[12px] tw-w-[12px]'),
              !isColorVariant &&
                !isTextOnly &&
                (isCircle ? 'tw- tw-px-[6px] tw-py-[3.5px]' : 'tw-px-[7px] tw-py-[4px]'),
              isCircle && 'tw-rounded-[12px]',
              'tw-font-normal',
              overrideTextColor
                ? `tw-text-[${overrideTextColor}]`
                : 'tw-text-newBlack/[0.9]'
            )}
            style={{
              border: (!isTextOnly || overrideTextColor) && '0.5px solid',
              borderColor: overrideTextColor
                ? hexToRgba(overrideTextColor, 0.4)
                : '#111C3633',
              background:
                variant?.colour_code ||
                (overrideTextColor
                  ? 'transparent'
                  : !isTextOnly
                    ? '#ffffff'
                    : 'transparent'),
              ...variantTextStyles.style,
              ...variantSingularStyle.style,
            }}
          >
            {!isColorVariant ? variantName : ''}
            {isTextOnly &&
              config?.separateVariantWithComa &&
              index !== arr.length - 1 &&
              ','}
          </div>
        );
      })}
      {variantsList?.length - choosenVariantList?.length > 0 && (
        <div
          className={classNames(
            'tw-flex tw-items-center tw-justify-center tw-font-medium',
            !isTextOnly &&
              (!isMobile ? 'tw-h-[8px] tw-w-[11px]' : 'tw-h-[12px] tw-w-[12px]'),
            !isTextOnly &&
              (isCircle ? 'tw-px-[11.5px] tw-py-[3.5px]' : 'tw-px-[11px] tw-py-[4px]'),
            isCircle && 'tw-rounded-[12px]',
            overrideTextColor
              ? `tw-text-[${overrideTextColor}]`
              : 'tw-text-newBlack/[0.9]'
          )}
          style={{
            border: (!isTextOnly || overrideTextColor) && '0.5px solid',
            borderColor: overrideTextColor
              ? hexToRgba(overrideTextColor, 0.4)
              : '#111C3633',
            background: overrideTextColor
              ? 'transparent'
              : !isTextOnly
                ? '#ffffff'
                : 'transparent',
            ...variantTextStyles.style,
            ...extraVariantSingularStyle.style,
          }}
        >
          +{variantsList?.length - choosenVariantList?.length}
        </div>
      )}
    </div>
  );
}
