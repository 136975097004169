import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { getImagesToRender } from '../utils';
import styles from '../styles.module.scss';
import {
  DotIndicatorComponent,
  useGetChevronComponent,
} from '../../CardFrameCollection/components/CarouselIndicator';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { useGetDeviceType } from 'src/hooks/useGetDeviceType';
import { onCarouselScroll } from '../../ListPresentation/utils';
import { getCurrentClass } from '../../CardFrameCollection/utils';
import { AnimatePresence, motion } from 'framer-motion';

export default function ImageRenderer({
  images,
  config,
  removeGlobalStyle,
  productData,
  isButtonInOverlay,
}) {
  const {
    widgetContextState: { previewDevice, isBuilder },
  } = useWidgetDndContextData();

  const slideShowRef = useRef<any>();
  const [imageContainerConfigs, setImageContainerConfigs] = useState<{
    isHovered: boolean;
    type: 'nextImage' | 'runSlideshow' | '';
  }>({
    isHovered: false,
    type: '',
  });
  const [initialImagesToRender, setInitialImagesRender] = useState([]);
  const [imagesToRender, setImagesToRender] = useState([]);

  const isNextImageAnimationFallback =
    config?.hoverType === 'nextImage' && imagesToRender?.length < 2;

  const { deviceType } = useGetDeviceType();
  const isMobile = isBuilder ? previewDevice === 'mobile' : deviceType === 'mobile';
  const imagesContainerRef = useRef(null);
  const [isImageHovered, setIsImageHovered] = useState(false);
  const [carouselIndicatorConfigs, setCarouselIndicatorConfigs] = useState({
    scrollPercentage: 0,
    overflowSectionCount: imagesToRender?.length,
    currentFocusedSectionItem: 1,
    direction: 'to-right',
  });

  useEffect(() => {
    const imagesToRender = getImagesToRender(config, images);
    setImagesToRender(images);
    setCarouselIndicatorConfigs((data) => ({
      ...data,
      overflowSectionCount: imagesToRender?.length,
    }));
    setInitialImagesRender(imagesToRender);
  }, [images, config?.imageType]);

  useEffect(() => {
    if (
      !imageContainerConfigs.isHovered &&
      imageContainerConfigs.type === 'runSlideshow'
    ) {
      resetSlideShow();
    }
  }, [imageContainerConfigs.isHovered]);

  // if (productData?.name === 'Trio showpiece') {
  //   console.log(
  //     'carouselIndicatorConfigs ',
  //     productData?.name + ':  ',
  //     carouselIndicatorConfigs
  //   );
  // }

  useEffect(() => {
    if (imageContainerConfigs.type === 'runSlideshow')
      renderIncrementalImage(carouselIndicatorConfigs.currentFocusedSectionItem - 1);
  }, [
    carouselIndicatorConfigs.scrollPercentage,
    initialImagesToRender,
    imageContainerConfigs.type,
  ]);

  const { LeftArrow, RightArrow } = useGetChevronComponent({
    type: config?.arrowShape || 'circular',
    onNextArrowClick: (e) => {
      e?.stopPropagation();
      imagesContainerRef.current?.scrollBy({
        left: imagesContainerRef.current?.clientWidth,
      });
    },
    onPrevArrowClick: (e) => {
      e?.stopPropagation();
      imagesContainerRef.current?.scrollBy({
        left: -1 * imagesContainerRef.current?.clientWidth,
      });
    },
    scrollPercentage: carouselIndicatorConfigs.scrollPercentage,
    isMobile,
    colorConfig: {
      arrowColor: config?.arrowColor,
      shapeColor: '#fff',
    },
    dimensions: {
      width: {
        mobile: '12px',
        desktop: '24px',
      },
      height: {
        mobile: '12px',
        desktop: '24px',
      },
    },
  });

  function renderIncrementalImage(currentScrolledImageIndex) {
    const initialImagesLength = initialImagesToRender?.length;
    if (initialImagesLength === 1) return;
    if (currentScrolledImageIndex % initialImagesLength === initialImagesLength - 1) {
      const nextImageToPick =
        (currentScrolledImageIndex % initialImagesLength) + 1 >= initialImagesLength
          ? 0
          : (currentScrolledImageIndex % initialImagesLength) + 1;
      setImagesToRender((data) =>
        currentScrolledImageIndex % initialImagesLength === initialImagesLength - 1
          ? [
              ...data,
              ...initialImagesToRender.slice(
                nextImageToPick,
                nextImageToPick + 4 >= initialImagesLength
                  ? initialImagesLength
                  : nextImageToPick + 1
              ),
            ]
          : data
      );
    }
  }

  const startSlideShow = () => {
    if (imagesContainerRef.current) {
      slideShowRef.current = setInterval(() => {
        const { scrollLeft, clientWidth } = imagesContainerRef.current || {};
        renderIncrementalImage(Math.ceil(scrollLeft / clientWidth));
        imagesContainerRef.current?.scrollBy({
          left: clientWidth,
        });
      }, config?.autoSlideTimer * 1000);
    }
  };

  function pauseSlideShow() {
    slideShowRef.current && clearInterval(slideShowRef.current);
  }

  function onSlideShowOver() {
    resetSlideShow();
  }

  function resetSlideShow() {
    slideShowRef.current && clearInterval(slideShowRef.current);
    setImagesToRender(initialImagesToRender);
    imagesContainerRef.current?.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }

  function getImage() {
    const hoverType = config?.hoverType;
    // if (isMobile || (config?.imageType !== 'single' && imagesToRender?.length < 2)) {
    //   hoverType = 'zoomIn';
    // }

    switch (hoverType) {
      case 'zoomIn':
      case 'ambient':
      case 'none':
        return imagesToRender?.map((imageData) => {
          return (
            <img
              alt={imageData.alt_text}
              key={imageData?.image_url}
              loading={isBuilder ? 'eager' : 'lazy'}
              className={classNames(
                'tw-h-full tw-w-full tw-flex-shrink-0 tw-object-cover',
                '[&:hover]:tw-shadow-[0px_2px_2px_0px_#00000040]',
                removeGlobalStyle,
                !isMobile && contentAnimationStyle[getCurrentClass(hoverType)]
              )}
              src={imageData?.image_url}
            />
          );
        });
      case 'nextImage':
        const variants = {
          hidden: {
            opacity: 0,
          },
          visible: { opacity: 1 },
        };
        return (
          <div
            className={classNames(
              'tw-relative tw-h-full tw-w-full tw-flex-shrink-0  tw-select-none',
              '[&:hover]:tw-shadow-[0px_2px_2px_0px_#00000040]'
            )}
            onMouseEnter={() =>
              setImageContainerConfigs({ isHovered: true, type: 'nextImage' })
            }
            onMouseLeave={() => setImageContainerConfigs({ isHovered: false, type: '' })}
          >
            {imagesToRender?.length > 1 ? (
              <AnimatePresence>
                {imagesToRender?.map((imageData, index) => {
                  return (
                    <motion.img
                      alt={imageData.alt_text}
                      key={imageData?.image_url + index}
                      initial={
                        !isMobile &&
                        imagesToRender.length > 1 &&
                        index === 0 &&
                        !imageContainerConfigs.isHovered
                          ? 'visible'
                          : 'hidden'
                      }
                      animate={
                        index === 0 && imageContainerConfigs.isHovered
                          ? 'hidden'
                          : 'visible'
                      }
                      exit={
                        index === 1 && !imageContainerConfigs.isHovered
                          ? 'hidden'
                          : 'visible'
                      }
                      variants={variants}
                      loading={isBuilder ? 'eager' : 'lazy'}
                      className={classNames(
                        'tw-absolute tw-h-full tw-w-full tw-object-cover tw-transition-all tw-ease-linear',
                        '[&:hover]:tw-shadow-[0px_2px_2px_0px_#00000040]',
                        removeGlobalStyle,
                        index === 0
                          ? 'tw-z-[1] tw-opacity-[1]'
                          : 'tw-z-[0] tw-opacity-[0]'
                      )}
                      src={imageData?.image_url}
                    />
                  );
                })}
              </AnimatePresence>
            ) : (
              imagesToRender?.map((imageData, index) => {
                return (
                  <img
                    alt={imageData.alt_text}
                    key={imageData?.image_url + index}
                    loading={isBuilder ? 'eager' : 'lazy'}
                    className={classNames(
                      'tw-absolute tw-h-full tw-w-full tw-object-cover tw-transition-all tw-ease-linear',
                      '[&:hover]:tw-shadow-[0px_2px_2px_0px_#00000040]',
                      removeGlobalStyle,
                      !isMobile && contentAnimationStyle[getCurrentClass('zoomIn')]
                    )}
                    src={imageData?.image_url}
                  />
                );
              })
            )}
          </div>
        );
      case 'runSlideshow': {
        return (
          <div
            className={classNames(
              'tw-relative tw-h-full tw-w-full tw-select-none',
              '[&:hover]:tw-shadow-[0px_2px_2px_0px_#00000040]'
            )}
            onMouseEnter={() =>
              setImageContainerConfigs({ isHovered: true, type: 'runSlideshow' })
            }
            onMouseLeave={() => {
              onSlideShowOver();
              setImageContainerConfigs({ isHovered: false, type: '' });
            }}
          >
            {imagesToRender?.length && (
              <div
                className={classNames(
                  'tw-pointer-events-none tw-absolute tw-z-[4] tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between tw-p-[10px]  tw-transition-opacity',
                  removeGlobalStyle,
                  !imageContainerConfigs.isHovered &&
                    !isMobile &&
                    'tw-pointer-events-none tw-opacity-0'
                )}
              >
                <LeftArrow />
                <DotIndicatorComponent
                  styles={
                    !isButtonInOverlay
                      ? { marginTop: 'auto' }
                      : {
                          position: 'absolute',
                          bottom: '60px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                        }
                  }
                  overflowSectionCount={carouselIndicatorConfigs.overflowSectionCount}
                  currentFocusedSectionItem={
                    (initialImagesToRender?.length <
                    carouselIndicatorConfigs.currentFocusedSectionItem
                      ? carouselIndicatorConfigs.currentFocusedSectionItem
                      : imagesToRender?.length) %
                      initialImagesToRender?.length ===
                    0
                      ? initialImagesToRender?.length
                      : (initialImagesToRender?.length <
                        carouselIndicatorConfigs.currentFocusedSectionItem
                          ? carouselIndicatorConfigs.currentFocusedSectionItem
                          : imagesToRender?.length) % initialImagesToRender?.length
                  }
                  progressIndicatorColor={config?.activeDotColor}
                  direction={carouselIndicatorConfigs.direction}
                  containerWidth={40}
                  dimensions={{
                    width: 'tw-w-[4px]',
                    widthOfSelected: 'tw-w-[4px]',
                    height: 'tw-h-[4px]',
                  }}
                />
                <RightArrow />
              </div>
            )}
            <div
              ref={imagesContainerRef}
              className="no-scrollbar tw-flex tw-h-full tw-w-full tw-touch-none tw-overflow-auto"
              onScroll={(e) => onCarouselScroll({ e, setCarouselIndicatorConfigs })}
              onMouseEnter={startSlideShow}
              onMouseLeave={pauseSlideShow}
            >
              {imagesToRender?.map((imageData, index) => {
                return (
                  <img
                    alt={imageData.alt_text}
                    key={imageData?.image_url + index}
                    loading={isBuilder ? 'eager' : 'lazy'}
                    className={classNames(
                      'tw-h-full tw-w-full tw-flex-shrink-0 tw-object-cover',
                      removeGlobalStyle
                    )}
                    src={imageData?.image_url}
                  />
                );
              })}
            </div>
          </div>
        );
      }
      case 4: {
        return (
          <div
            onMouseOver={() => setIsImageHovered(true)}
            onMouseLeave={() => setIsImageHovered(false)}
            className={classNames(styles['scroll-container'], 'tw-h-full tw-w-full')}
          >
            <div
              className={classNames(
                isImageHovered && styles['carousel-primary'],
                'tw-h-full tw-w-full'
              )}
            >
              {imagesToRender?.map((imageData) => {
                return (
                  <img
                    alt={imageData.alt_text}
                    key={imageData?.image_url}
                    loading={isBuilder ? 'eager' : 'lazy'}
                    className={classNames(
                      'tw-h-full tw-w-full tw-object-cover',
                      removeGlobalStyle
                    )}
                    src={imageData?.image_url}
                  />
                );
              })}
            </div>
            <div
              className={classNames(
                isImageHovered && styles['carousel-primary'],
                isImageHovered && styles['carousel-secondary'],
                'tw-h-full tw-w-full'
              )}
            >
              {imagesToRender?.map((imageData) => {
                return (
                  <img
                    alt={imageData.alt_text}
                    key={imageData?.image_url}
                    loading={isBuilder ? 'eager' : 'lazy'}
                    className={classNames(
                      'tw-h-full tw-w-full tw-object-cover',
                      removeGlobalStyle
                    )}
                    src={imageData?.image_url}
                  />
                );
              })}
            </div>
          </div>
        );
      }
      default:
        return null;
    }
  }

  return (
    <div
      className={classNames(
        'tw-h-full tw-w-full tw-overflow-hidden',
        removeGlobalStyle || 'imgWrapperGlobalStyle'
      )}
    >
      <div
        className={classNames(
          removeGlobalStyle,
          'tw-relative tw-flex tw-h-full tw-w-full tw-select-none',
          // isNextImageAnimation &&
          //   !isNextImageAnimationFallback &&
          //   `[&:hover>*:nth-child(1)]:tw-opacity-[0] [&:hover>*:nth-child(2)]:tw-opacity-[1]`, // [&>*]:tw-duration-[800ms] [&>*:nth-child(2)]:tw-delay-[700ms]
          (['zoomIn', 'ambient']?.includes(config?.hoverType) ||
            isNextImageAnimationFallback) &&
            'tw-overflow-hidden'
        )}
      >
        {getImage()}
      </div>
    </div>
  );
}
