import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  DISCOUNT_IMAGE_TAG,
  getCartWrapperConfigs,
  getCTAHoverAnimationClass,
  getProductDetailsWrapperStyles,
  getWrapperStylesBasedOnGlobal,
} from './utils';
import ImageRenderer from './components/ImageRenderer';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { isEmpty } from 'lodash';
import { BelowElementPicker, OverlayElementPicker } from './components/ElementPicker';
import { getDiscountPercentage } from 'src/utils/getDiscountPercentage';
import { scrollItemAndRemoveDetails } from 'src/utils/scrollItemAndRemoveDetails';
import { ITEM_ACTIVE_STATUS } from 'src/utils/constants';
import { OutOfStockStrip } from './components/OutOfStockStrip';
import { useItemClick } from 'src/hooks/useItemClick';
import {
  getShapeStyles,
  IMAGE_SHAPE_OVERRIDING_GLOBAL_STYLE,
} from '../CardFrameCollection/utils';
import { itemIndexFromCart } from 'src/utils/itemIndexFromCart';
import { useSSRSelector } from 'src/redux';

interface IProductCard {
  productData: any;
  itemIndex: number;
  onItemClick?: (...args: any[]) => void;
  configs?: {
    cardWrapperConfigs?: {
      style: React.CSSProperties;
      className: string;
      isCardLayout: boolean;
      isFullWidth: boolean;
    };
    // This is used to override any class applied by cardWrapperConfigs
    customWrapperClass?: string;
    overrideStyles?: {
      textColor?: string;
    };
    isMobile: boolean;
    dimensions?: {
      cardWidth: number | string;
      cardHeight: number | string;
    };
    snackbarMethods?: any;
  };
}

export function ProductCard({
  productData,
  itemIndex,
  onItemClick: onItemClickFromProp,
  ...restProps
}: IProductCard) {
  const cardRef = useRef(null);
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  const {
    productCard: { infoList },
  } = globalStyle;
  const cartData = useSSRSelector((state) => state.cartReducer);

  const itemIndexInCart = itemIndexFromCart(cartData, productData);
  const isItemInCart = itemIndexInCart >= 0;

  // This acts as a fallback
  const [onItemClick] = useItemClick();

  const isMobile = restProps?.configs?.isMobile;

  const [, setDiscountPercent] = useState(0);

  const buttonElement = infoList?.find((item) => item.type === 'button');
  const layoutElement = infoList?.find((item) => item.type === 'layout');
  const isButtonBelowImage =
    buttonElement?.elementPosition === 'below' &&
    buttonElement?.config?.showAddToCart &&
    buttonElement?.config?.buttonConfig.find((item) => item.isShow);
  const cardWrapperConfigs =
    restProps?.configs?.cardWrapperConfigs ||
    getCartWrapperConfigs(layoutElement?.config);
  const isFullWidthImgInCard =
    cardWrapperConfigs.isCardLayout && cardWrapperConfigs.isFullWidth;

  const uiComponents = infoList.reduce(
    (acc, current) => {
      if (current.elementPosition === 'below') {
        if (current.config.buttonType !== 'iconFrontOfProductName') {
          acc.belowElements.columnOne.push(current);
        } else {
          acc.belowElements.columnTwo.push(current);
        }
      }
      if (current.elementPosition === 'overlay') {
        acc.overlayElements.push(current);
      }
      if (current.type === 'image') {
        acc.imageElement = current;
      }
      return acc;
    },
    {
      belowElements: {
        columnOne: [],
        columnTwo: [],
      },
      overlayElements: [],
      imageElement: {},
    }
  );

  useEffect(() => {
    if (productData && productData?.is_active !== ITEM_ACTIVE_STATUS.HIDDEN) {
      const percent = getDiscountPercentage(
        productData?.price,
        productData?.discounted_price
      );
      setDiscountPercent(percent ? percent : 0);
      scrollItemAndRemoveDetails(productData, cardRef);
    }
  }, [productData]);

  const productDetailsWrapper = getProductDetailsWrapperStyles(layoutElement?.config);

  const imagesToShow = productData?.images;

  const shapeStyleCalculated = getShapeStyles(
    uiComponents?.imageElement?.config?.imageShape,
    restProps?.configs?.dimensions.cardWidth as number,
    isFullWidthImgInCard
  );
  const shapeStyle = shapeStyleCalculated[itemIndex % shapeStyleCalculated?.length];

  // To add a class that removes global styling
  const removeGlobalStyle =
    isFullWidthImgInCard &&
    !IMAGE_SHAPE_OVERRIDING_GLOBAL_STYLE.includes(
      uiComponents.imageElement?.config?.imageShape
    )
      ? 'removeGlobalOverride imgWrapperGlobalStyle globalImgStyleConsumer addTopLeftGlobalRadius addTopRightGlobalRadius'
      : '';

  const { addWrapperGlobalStyle } = getWrapperStylesBasedOnGlobal(
    cardWrapperConfigs,
    globalStyle
  );

  const isButtonInOverlay = uiComponents.overlayElements.find(
    (element) => element.type === 'button'
  );

  const ctaHoverAnimationClass = getCTAHoverAnimationClass({
    buttonElement,
    isItemInCart,
    isMobile,
  });

  const discountElementConfig = uiComponents.overlayElements.find(
    (element) => element.type === 'discount'
  )?.config;

  return (
    <article
      onClick={(e) => (onItemClickFromProp || onItemClick)?.(productData, e)}
      ref={cardRef}
      className={classNames(
        `tw-flex tw-h-full tw-w-[100%] tw-cursor-pointer tw-flex-col`,
        cardWrapperConfigs.isCardLayout
          ? classNames(
              !cardWrapperConfigs.isFullWidth &&
                (isMobile ? 'tw-px-[10px] tw-py-[10px]' : 'tw-px-[14px] tw-py-[14px]')
            )
          : '',
        cardWrapperConfigs.className,
        addWrapperGlobalStyle.className,
        restProps.configs.customWrapperClass,
        // For overlay based scenario
        ctaHoverAnimationClass
      )}
      style={{ ...cardWrapperConfigs.style, ...addWrapperGlobalStyle.style }}
    >
      <div
        style={{
          height: `${typeof restProps?.configs?.dimensions.cardHeight === 'number' ? `${restProps?.configs?.dimensions.cardHeight}px` : restProps?.configs?.dimensions.cardHeight || 'unset'}`,
          // Removing border widths horizontally
          width: `${typeof restProps?.configs?.dimensions.cardWidth === 'number' ? `${restProps?.configs?.dimensions.cardWidth - (cardWrapperConfigs.isFullWidth ? 2 : 0)}px` : restProps?.configs?.dimensions.cardWidth || 'unset'}`,
          ...shapeStyle?.style,
        }}
        className={classNames('tw-relative tw-overflow-hidden', shapeStyle?.className)}
      >
        {!isEmpty(uiComponents.imageElement) ? (
          <ImageRenderer
            images={imagesToShow}
            config={uiComponents.imageElement?.config}
            removeGlobalStyle={removeGlobalStyle}
            isButtonInOverlay={isButtonInOverlay}
            productData={productData}
          />
        ) : null}
        <div
          className={classNames(
            'overlayWrapper tw-pointer-events-none tw-absolute tw-inset-0',
            isMobile ? 'tw-p-[8px]' : 'tw-p-[10px]',
            removeGlobalStyle ||
              'imgWrapperGlobalStyle ' +
                (discountElementConfig?.discountTag === DISCOUNT_IMAGE_TAG.SQUARE
                  ? ' tw-overflow-hidden'
                  : '')
          )}
        >
          {uiComponents.overlayElements.map((item, index) => (
            <OverlayElementPicker
              key={index}
              elementData={item}
              productData={productData}
              isMobile={isMobile}
              snackbarMethods={restProps?.configs?.snackbarMethods}
            />
          ))}
          <OutOfStockStrip
            isMobile={isMobile}
            isVisible={!productData?.available}
            isFullWidthCard={cardWrapperConfigs.isFullWidth}
          />
        </div>
      </div>
      <div
        className={classNames(
          'cardAllInfoWrapper tw-flex tw-flex-grow tw-flex-row tw-gap-[16px]',
          cardWrapperConfigs.isFullWidth &&
            (isMobile
              ? `tw-px-[10px] ${!isButtonBelowImage ? 'tw-pb-[16px]' : 'tw-pb-[10px]'}`
              : `tw-px-[12px] ${!isButtonBelowImage ? 'tw-pb-[20px]' : 'tw-pb-[10px]'}`),
          !isMobile ? 'tw-mt-[16px]' : 'tw-mt-[12px]'
        )}
      >
        {/** Get columns & render each of them */}
        {Object.values(uiComponents.belowElements).map((column: any[], index) => {
          const isFirstColumn = !index;
          if (!column.length) {
            return null;
          }
          return (
            <div
              key={index}
              className={classNames(
                'cardDetailsWrapper tw-flex tw-flex-grow tw-flex-col',
                isFirstColumn && 'tw-flex-grow',
                productDetailsWrapper.className
              )}
            >
              {column.map((item, index, array) => (
                <BelowElementPicker
                  key={index}
                  elementData={item}
                  renderrerList={array}
                  productData={productData}
                  isMobile={isMobile}
                  adjacentElements={{
                    next: column[index + 1]?.type,
                    above: column[index - 1]?.type,
                    // Treat the all of the remaining elements as to not put spacing if they are directly above the CTA
                    isLastItem: column[index + 1]?.type === 'button',
                    isFirstItem: column[index + 1]?.type === undefined,
                  }}
                  overrideStyles={restProps.configs.overrideStyles}
                  snackbarMethods={restProps?.configs?.snackbarMethods}
                />
              ))}
            </div>
          );
        })}
      </div>
    </article>
  );
}
