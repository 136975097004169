import Rating, { ratingClasses } from '@mui/material/Rating';
import styled from 'styled-components';

export const ProductRating = styled(Rating)(
  (props?: { emptyIconColor?: string; fontSize?: string }) => ({
    [`&.${ratingClasses.root}`]: {
      fontSize: props.fontSize || '16px',
      lineHeight: 1,
      height: props.fontSize || '16px',
    },
    //   [`& .${ratingClasses.iconFilled}`]: {
    //     color: '#ff6d75',
    //   },
    [`& .${ratingClasses.iconEmpty}`]: {
      color: props.emptyIconColor || '#171C2A33',
    },
  })
);
